import $http from '@/utils/http';

// 获取云空间数量
export function get_space_num(data) {
  return $http.get('/api/Index/get_space_num', data, 'loadingDiv');
}

// 获取云空间数量
export function get_account_num(data) {
  return $http.get('/api/Index/get_account_num', data, 'loadingDiv');
}

// 数据概览
export function indexData_overview(data) {
  return $http.get('/api/index/data_overview', data);
}

// 获取企业信息
export function get_enterinformation_info(data) {
  return $http.get('/api/Index/get_enterinformation_info', data);
}
