<template>
  <div class="body">
    <el-row>
      <el-col :span="19">
        <div class="main-con" style="padding: 10px 15px">
          <div style="margin: 10px; padding: 10px 15px; background-color: white">
            <h4 style="margin: 10px 0">数据概览</h4>
            <div style="border-bottom: 1px solid #bbb"></div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-around;
                text-align: center;
              "
            >
              <div>
                <h1 style="font-size: 30px; margin: 20px 0 10px; height: 40px">
                  {{ overView.models_num }}
                </h1>
                <p>数字构件</p>
              </div>
              <div>
                <h1 style="font-size: 30px; margin: 20px 0 10px; height: 40px">
                  {{ overView.intentionr }}
                </h1>
                <p>企业线索</p>
              </div>
              <div>
                <h1 style="font-size: 30px; margin: 20px 0 10px; height: 40px">
                  {{ overView.account_count }}
                </h1>
                <p>已创建账号</p>
              </div>
              <div>
                <h1 style="font-size: 30px; margin: 20px 0 10px; height: 40px">
                  {{ overView.login_num }}
                </h1>
                <p>今日企业登录人数</p>
              </div>
              <div>
                <h1 style="font-size: 30px; margin: 20px 0 10px; height: 40px">
                  {{ overView.brand_show }}
                </h1>
                <p>今日品牌曝光</p>
              </div>
            </div>
          </div>
          <div>
            <el-row>
              <el-col :span="9">
                <div style="margin: 10px">
                  <div style="padding: 10px 15px; background-color: white">
                    <h4 style="margin: 10px 0">企业通知</h4>
                    <div style="border-bottom: 1px solid #bbb"></div>
                    <div>
                      <!-- <div v-for="i in 4" :key="i"
                        style="display: flex;align-items: center;justify-content: space-between;margin: 10px 0;">
                        <span>有一条新的账单等待支付!</span>
                        <span style="font-size: 12px;">今天19：22：32</span>
                      </div> -->
                      <div style="text-align: center; line-height: 100px; color: #bbb">
                        暂无数据
                      </div>
                      <p
                        style="text-align: center; cursor: pointer"
                        @click="$router.push('/orderManagement')"
                      >
                        查看更多
                      </p>
                    </div>
                  </div>
                  <div
                    style="
                      background-color: #d0d0d0;
                      margin-top: 20px;
                      text-align: center;
                      height: 200px;
                    "
                  >
                    <el-carousel trigger="click" height="200px">
                      <el-carousel-item v-for="item in 4" :key="item">
                        <img :src="img" alt="" style="height: 200px" />
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </el-col>
              <el-col :span="15">
                <div style="margin: 10px">
                  <div style="padding: 10px 15px; background-color: white">
                    <h4 style="margin: 10px 0">快捷入口</h4>
                    <div style="border-bottom: 1px solid #bbb"></div>
                    <div>
                      <p class="btn" @click="$router.push('/model')">上传模型</p>
                      <p class="btn">编辑企业信息</p>
                      <p class="btn">套餐续费</p>
                      <p class="btn">账号扩容</p>
                      <p class="btn" @click="$router.push('/orderManagement')">待支付订单</p>
                      <p class="btn" @click="$router.push('/invoice')">发票管理</p>
                      <p class="btn" @click="$router.push('/groupList')">账号管理</p>
                    </div>
                  </div>
                  <div style="padding: 10px 15px; background-color: white; margin-top: 20px">
                    <h4 style="margin: 10px 0">模型动态</h4>
                    <div style="border-bottom: 1px solid #bbb"></div>
                    <div>
                      <!-- <div v-for="i in 6" :key="i"
                        style="display: flex;align-items: center;justify-content: space-between;margin: 10px 0;">
                        <span>圣伯雅AXU8071模型状态调整为<i>已下线</i></span>
                        <span style="font-size: 12px;">今天19：22：32</span>
                      </div> -->
                      <div style="text-align: center; line-height: 100px; color: #bbb">
                        暂无数据
                      </div>
                      <p
                        style="text-align: center; cursor: pointer; margin-top: 18px"
                        @click="$router.push('/model')"
                      >
                        查看更多
                      </p>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
      <el-col :span="5">
        <div style="margin: 20px 20px 0 0">
          <div style="background-color: white; padding: 20px">
            <div style="text-align: center">
              <div
                style="
                  background-color: #f1f1f1;
                  display: inline-block;
                  width: 130px;
                  text-align: center;
                  font-size: 0;
                "
              >
                <!-- <img :src="infoData.e_logo" alt="" style="width: 130px;height: 60px;"> -->
                <el-image
                  style="width: 130px; height: 60px"
                  :src="infoData.e_logo"
                  fit="cover"
                  :preview-src-list="[infoData.e_logo]"
                ></el-image>
              </div>
              <h4>{{ infoData.e_name }}</h4>
            </div>
            <div style="font-size: 12px; color: #5a5d54">
              <p>已使用智装荟：{{ infoData.e_used_day }}天</p>
              <p>
                SaaS服务有效期：{{ infoData.e_tariff_day ? infoData.e_tariff_day.day : 0 }}天{{
                  infoData.e_tariff_day ? infoData.e_tariff_day.hour : 0
                }}时
              </p>
            </div>
            <div style="border-bottom: 1px solid #bbb; margin: 25px 0"></div>
            <h5 style="margin-bottom: 0">云空间</h5>
            <div>
              <div style="font-size: 12px; display: flex; justify-content: space-between">
                <p>{{ yun.used }}GB/{{ yun.total }}GB</p>
                <p style="color: #0076ff">去扩容</p>
              </div>
              <el-progress
                :show-text="false"
                :percentage="percentage(yun.used, yun.total)"
                color="#29303E"
              ></el-progress>
            </div>
            <h5 style="margin-bottom: 0">账号数据</h5>
            <div>
              <div style="font-size: 12px; display: flex; justify-content: space-between">
                <p>{{ accountNum.used }}个/{{ accountNum.total }}个</p>
                <p style="color: #0076ff">去扩容</p>
              </div>
              <el-progress
                :show-text="false"
                :percentage="percentage(accountNum.used, accountNum.total)"
                color="#29303E"
              ></el-progress>
            </div>
            <p style="text-align: center; cursor: pointer; margin-bottom: 0">查看详情</p>
          </div>
          <!-- <div
            style="
              margin-top: 20px;
              background-color: white;
              padding: 10px 20px 26px;
            "
          >
            <h4 style="margin: 10px 0">KA服务经理</h4>
            <div style="border-bottom: 1px solid #bbb"></div>
            <h4 style="margin: 10px 0">张XX</h4>
            <div style="font-size: 13px">
              <p>电话：178 9898 7588</p>
              <p>邮箱：zhangxx@miaoxiang.co</p>
            </div>
          </div> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  get_account_num,
  get_enterinformation_info,
  get_space_num,
  indexData_overview,
} from '../../common/home';

export default {
  name: 'home',
  data() {
    return {
      // 云空间数据
      yun: {},
      // 账号数量
      accountNum: {},
      // 轮播图片
      img: require('../../assets/947.png'),
      // 数据概览
      overView: [],
      // 企业数据
      infoData: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const loadingInstance = this.$loading({
        target: '.main-con',
        lock: true,
        text: '正在加载首页数据中，请稍候',
      });
      get_space_num().then((res) => {
        if (res.code == 200) {
          this.yun = res.result;
        }
      });
      get_account_num().then((res) => {
        if (res.code == 200) {
          this.accountNum = res.result;
        }
      });
      indexData_overview().then((res) => {
        if (res.code == 200) {
          this.overView = res.result;
        }
      });
      get_enterinformation_info().then((res) => {
        if (res.code == 200) {
          this.infoData = res.result;
          // ~ 
          if (this.infoData && this.infoData.e_id) { 
            this.$store.dispatch('SET_E_ID', {
              e_id: this.infoData.e_id
            }).catch((ex) => { console.log(ex) });
          }
          this.$nextTick(() => {
            loadingInstance.close();
          });
        }
      });
    },
    // 百分比
    percentage(a1, a2) {
      if (a1 && a2) {
        if (a1 != undefined && a2 != undefined) {
          return (a1 / a2) * 100;
        } else {
          return 0;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  font-size: 14px;
}

.btn {
  width: 100px;
  background-color: #f2f2f2;
  padding: 5px 20px;
  display: inline-block;
  margin: 10px 10px 0 0;
  text-align: center;
  cursor: pointer;
}

.btn:hover {
  background-color: #29303e;
  color: white;
  border-radius: 5px;
}

i {
  color: #0076ff;
  font-style: normal;
}
</style>
